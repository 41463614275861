<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="商品名称" prop="goods_name">
          <el-input
            size="mini"
            v-model.trim="table.params.goods_name"
            placeholder="请输入商品名称"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单分类" prop="cate_id">
          <el-select v-model="table.params.cate_id" filterable placeholder="请选择" @focus="getOrderCateSel" clearable @change="onSearch">
            <el-option
              v-for="item in orderCateArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch">
            <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商名称" prop="supplier_id">
          <el-select v-model="table.params.supplier_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getSupplierSel" @change="onSearch">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset2">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" plain @click="handleExport" icon="el-icon-download" >导出</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:goods_name="{row}">
        <div style="display: flex;align-items: center;">
          <el-image
            v-if="!!row.image"
            style="width: 40px; height: 40px"
            :src="row.image"
            fit="cover"
            :preview-src-list="[row.image]"></el-image>
          <el-image
            v-else
            style="width: 40px; height: 30px"
            :src="require('@/assets/img/logo-icon.jpeg')"
            fit="cover"
            :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
          <span style="margin-left:10px;">{{row.goods_name}}</span>
        </div>
      </template>
      <template v-slot:purchase_price="{row}">
        <span>￥{{row.purchase_price}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showCharts(row)">查看趋势</el-button>
      </template>
    </VTable>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
      center>
      <el-alert
        v-if="this.multipleSelection.length == 0"
        title="提示：您未选择商品，系统将会导出列表全部信息"
        type="warning"
        :closable="false"
        style="margin-bottom:10px;">
      </el-alert>
      <el-form :model="form.data" :rules="form.rules" v-if="dialogVisible">
        <el-form-item label="采购日期" prop="date">
          <el-date-picker
            v-model="form.data.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="selpickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确定导出</el-button>
      </span>
    </el-dialog>

    <!-- 趋势 -->
    <Charts ref="charts"></Charts>

  </div>
</template>

<script>
import Charts from './components/mycharts.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'

export default {
  name: 'purchasepriceIndex',
  components: {
    Charts,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      login_account: getStorage('account'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "goods_name", label: "商品名称", hidden: false },
        { name: "unit_name", label: "单位", width:'100', hidden: false},
        { name: "name", label: "规格",width:'120', hidden: false},
        { name: "purchase_price", label: "最新采购价",width:'120', hidden: false},
        { name: "supplier_name", label: "所属供应商", hidden: false},
        { name: "date", label: "采购日期", width:'120', hidden: false},
        { name: "delivery_time", label: "更新时间", width:'160', hidden: false},
        { name: "action", label: "操作",width: "100", fixed:"right", hidden: false }
      ],
      multipleSelection:[],
      table: {
        loading: false,
        params: {
          goods_name: '',
          address_id: '', // 食堂id
          supplier_id: '', // 供应商ID
          cate_id: '',//下单分类ID
          page: 1,
          count: 20
        },
        data: [],
        total: 0,
      },
      orderCateArr: [], // 下单分类
      supplierArr: [], // 供应商
      storeArr: [], // 食堂
      dialogVisible: false,
      form:{
        loading:false,
        data:{
          date:[]
        },
        rules: {
          date: [{ required: true, message:'请选择采购日期', trigger: 'change'}],
        }
      },
      selpickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.isRestart = false
          this.pickerMinDate = minDate.getTime()
          if (maxDate) {
            this.pickerMinDate = ''
          }
        },
        disabledDate: (time) => { //查询时间跨度为31天
          if (this.curveTime && this.isRestart) {
            return // 存在选中的日期且没有重新选择日期 不做禁用处理
          }
          if (this.pickerMinDate !== '') {
            const one = 31 * 24 * 3600 * 1000
            const minTime = this.pickerMinDate - one
            const maxTime = this.pickerMinDate + one
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/price/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 趋势
    showCharts(row) {
      let dom = this.$refs.charts
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    handleExport() {
      this.$nextTick(() => {
        const end = new Date();
        const start = new Date();
        this.form.data.date[0] = this.$moment(start).format("YYYY-MM-DD");
        this.form.data.date[1] = this.$moment(end).format("YYYY-MM-DD");
      })
      this.dialogVisible = true;
    },
    // 导出
    confirm() {
      let obj = '';
      if(this.multipleSelection.length > 0) {
        obj = {
          start: this.form.data.date[0],
          end: this.form.data.date[1],
          ids: this.multipleSelection.map(v => v.spec_id).join("|")
        }
      } else {
        obj = {
          start: this.form.data.date[0],
          end: this.form.data.date[1],
        }
      }
      let str = ""
      
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      let url = '/admin/price/export'
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      this.handleClose()
    },
    handleClose() {
      this.dialogVisible = false;
      this.form.data.date = []
    },
  }
}
</script>
