<template>
  <el-dialog
    append-to-body
    width="1250px"
    :title="`${goods_name}采购价趋势`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
  >
    <div class="date">
      <span>单位：{{unit_name}}</span>
      <span style="margin:50px;">规格：{{name}}</span>
      <span style="color: #2cb167">平均采购价：￥{{avg_price}}</span>
    </div>
    <div class="myCharts" style="width: 1200px;height: 500px;">
      <Echart :options="options" height="100%" width="100%"></Echart>
    </div>
    <template #footer>
      <el-row type="flex" justify="center">
        <el-button @click="toggle(false)">关闭</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { getStorage } from "@/storage";
import Echart from "@/components/echart/index.vue";
export default {
  name: "companyEdit",
  components: {
    Echart
  },
  data() {
    return {
      token: getStorage("token"),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      visible: false,
      goods_name: '',// 商品名称
      unit_name: '',//单位
      name: '', // 规格
      avg_price: '', // 平均采购价
      options: {
        // backgroundColor: "#0e1c47",
        color: ["#00B3F9"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(13, 64, 71, 0.50)",
          borderColor: "rgba(143, 225, 252, 0.60)",
          padding: 8,
          textStyle: {
            color: "#fff",
          },
          formatter: function (params) {
            // let res = '';
            let res = params[1].value == 0 ? '暂无采购价' : `￥${params[1].value}` ;
            return (res =
              params[0].name +
              "<br/>采购价" +
              " :  " +
              res +
              `<br/>` +
              params[2].seriesName +
              " : " +
              params[2].value );
          },
        },
        legend: {
          data: ["平均采购价", "当天最新采购价"],
          //   icon: 'rect', // 矩形标
          //   x: "right",
          textStyle: {
            color: "#000",
            fontSize: 14,
          },
          top: 10,
          //   right: 50,
          itemWidth: 20,
          itemHeight: 10,
          itemGap: 15,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisLine: {
            // 轴线设置
            show: false, // 显示轴线
          },
          axisLabel: {
            textStyle: {
              color: "#8c8f92", //更改坐标轴文字颜色737A85
              fontSize: 12, //更改坐标轴文字大小
              fontWeight: 400,
            },
            // interval: 0,
            // rotate: -50, //横坐标文字倾斜
          },
          // data: [
          //   "03-01",
          //   "03-02",
          //   "03-03",
          //   "03-04",
          //   "03-05",
          //   "03-06",
          //   "03-07",
          //   "03-08",
          //   "03-09",
          //   "03-10",
          //   "03-11",
          //   "03-12",
          //   "03-13",
          //   "03-14",
          //   "03-15",
          //   "03-16",
          //   "03-17",
          //   "03-18",
          //   "03-19",
          //   "03-20",
          //   "03-21",
          //   "03-22",
          //   "03-23",
          //   "03-24",
          //   "03-25",
          //   "03-26",
          //   "03-27",
          //   "03-28",
          //   "03-29",
          //   "03-30",
          // ],
          data: [],
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#737A85",
              type: "dashed",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#737A85", //更改坐标轴文字颜色
              fontSize: 12, //更改坐标轴文字大小
            },
          },
        },
        series: [
          {
            name: "平均采购价",
            type: "line",
            // smooth: true,
            // symbol: 'circle',
            symbolSize: 0,
            itemStyle: {
              // 折线拐点标志的样式
              borderWidth: "0",
              borderColor: "#2cb167",
              normal: {
                color: "#2cb167", //拐点颜色
              },
            },
            lineStyle: {
              color: "#2cb167",
              type: "dashed",
              width: 2 // 线宽
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: "当天最新采购价",
            type: "line",
            smooth: false, // 曲线是否平滑
            symbol: 'circle',
            symbolSize: 0,
            itemStyle: {
              //折线拐点标志的样式
              borderWidth: "0",
              borderColor: "#5470c6",
              normal: {
                color: "#ffe400", //拐点颜色
              },
            },
            lineStyle: {
              color: "#ffe400",
            },

            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: "供应商",
            type: "line",
            // smooth: true,
            // symbol: 'circle',
            symbolSize: 10,
            itemStyle: {
              //折线拐点标志的样式
              borderWidth: "2",
              borderColor: "#5470c6",
              normal: {
                color: "#ffe400", //拐点颜色
              },
            },
            lineStyle: {
              color: "#ffe400",
            },

            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    getDetail(row) {
      console.log(row)
      if (!!row.id) {
        this.goods_name= row.goods_name;
        this.unit_name= row.unit_name;
        this.name= row.name;
        let _params = {
          spec_id: row.spec_id,
          supplier_id: row.supplier_id,
        }
        this.$http.get('/admin/price/info',{params:_params}).then(res => {
          if(res.code == 1) {
            this.avg_price = res.data.avg_price;
            let _date = res.data.line.map((a) => a.date); // 日期
            let _line0 = res.data.line.map((v,i) => {
              return res.data.avg_price
            })
            console.log(_line0,"_line0")
            let _line1 = res.data.line.map((b) => b.purchase_price); // 采购价
            let _line2 = res.data.line.map((c) => c.supplier_name); //供应商
            this.options.xAxis.data = _date;
            this.options.series[0].data = _line0;
            this.options.series[1].data = _line1;
            this.options.series[2].data = _line2;
          }
        })
        
      }
    },
    reset(done) {
      done && done();
    },
    toggle(show) {
      this.visible = show;
      !show && this.reset();
    },
    toStr(value,key) {
      return value[key]
    }
  },
};
</script>
<style scoped>
.myCharts {
  width: 100%;
  min-width: 800px;
  height: 500px;
}
.date {
  text-align:center;
  margin-bottom:20px;
  font-size: 14px;
  font-weight: bold;
}
</style>
